<template>
    <div >
        <form action="" @submit.prevent="login">
            <v-text-field v-model="credentials.email"
                          label="E-mail"
                          type="email"
                          prefix="*"></v-text-field>
            <v-text-field v-model="credentials.password"
                          type="password"
                          label="Password"
                          prefix="*"></v-text-field>

            <v-btn
                color="success"
                class="mt-8"
                type="submit"
            >
                Login
            </v-btn>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      credentials: {
        email: '',
        password: ''
      }
    }
  },

  computed: {
    ...mapState({
      user: state => state.Store.user
    })
  },

  methods: {
    login () {
      this.$store.dispatch('login', this.credentials).then(() => {
        this.$axios.interceptors.request.use((config) => {
          const token = this.user.api_token
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
          }

          return config
        }, function (error) {
          // Do something with request error
          return Promise.reject(error)
        })
        this.$router.push({ name: 'promotions.index' })
      })
    }
  }
}
</script>

<style scoped>

</style>
